import styles from "./LogoGo.module.css";

export const LogoGo = ({
  color = "black",
}: {
  color?: string;
} = {}) => (
  <svg
    className={styles.gologo}
    viewBox="0 0 176 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      className={styles.o}
      d="M161.389 34.7492C163.911 34.7492 165.815 32.8457 165.815 30.3233C165.815 27.8009 163.911 25.8975 161.389 25.8975C158.866 25.8975 156.963 27.8009 156.963 30.3233C156.963 32.8457 158.866 34.7492 161.389 34.7492ZM146.734 30.3233L151.302 16.9034H171.478L176 30.3233L171.478 43.7432H151.302L146.734 30.3233Z"
      fill={color}
    />
    <path
      d="M133.695 31.0847C131.553 31.0847 129.984 29.5155 129.984 27.3738C129.984 25.232 131.553 23.7093 133.695 23.7093C135.836 23.7093 137.409 25.2785 137.409 27.3738C137.409 29.4691 135.839 31.0847 133.695 31.0847ZM130.315 16.9529C123.794 16.9529 119.894 21.3788 119.894 27.2809C119.894 33.1831 123.797 37.6553 130.315 37.6553H135.121C136.502 37.6553 137.452 38.5591 137.452 39.8435V40.3727C137.452 41.6572 136.502 42.6104 135.121 42.6104H120.751V50.4624H145.545V16.956H130.318L130.315 16.9529Z"
      fill={color}
    />
    <path
      d="M66.2115 16.9065H55.0757V43.7493H66.2115V16.9065Z"
      fill={color}
    />
    <path
      d="M26.6172 10.1935H37.5177V20.9981C37.5177 22.4249 38.3751 23.3317 39.7554 23.3317C41.1358 23.3317 41.9931 22.428 41.9931 20.9981V16.9065H52.8906V28.0918H37.0411C35.6607 28.0918 34.7075 28.9956 34.7075 30.28C34.7075 31.5644 35.6607 32.4681 37.0411 32.4681H52.8906V43.7494H41.9931V39.5618C41.9931 38.1351 41.1358 37.2282 39.7554 37.2282C38.3751 37.2282 37.5177 38.132 37.5177 39.5618V43.7494H26.6172V10.1966V10.1935Z"
      fill={color}
    />
    <path
      d="M0.105469 41.4869H14.9552C16.3356 41.4869 17.2858 40.5832 17.2858 39.2988C17.2858 38.0144 16.3356 37.0611 14.9552 37.0611L0.105469 36.9652V16.9034H24.4259V25.8511H9.57614C8.19578 25.8511 7.24252 26.7548 7.24252 28.0392C7.24252 29.3236 8.19578 30.2769 9.57614 30.2769H24.4259V50.4562H0.105469V41.4869Z"
      fill={color}
    />
    <path
      d="M81.4449 34.6098C83.8713 34.6098 85.7283 32.7528 85.7283 30.3264C85.7283 27.8999 83.8713 26.0429 81.4449 26.0429C79.0184 26.0429 77.1614 27.8999 77.1614 30.3264C77.1614 32.7528 79.0184 34.6098 81.4449 34.6098ZM81.9184 16.9065C90.1047 16.9065 95.719 22.3784 95.719 30.3295C95.719 38.2805 90.1016 43.7524 81.9184 43.7524H79.1112V50.4624H68.4026V16.9096H81.9184V16.9065Z"
      fill={color}
    />
    <path
      d="M74.2428 7.87223C74.2428 11.7007 71.1385 14.805 67.31 14.805C63.4815 14.805 60.3772 11.7007 60.3772 7.87223C60.3772 4.04373 63.4815 0.939453 67.31 0.939453C71.1385 0.939453 74.2428 4.04373 74.2428 7.87223Z"
      fill="#FF66FF"
    />
    <path
      d="M117.777 23.1213C117.777 26.9498 114.673 30.054 110.844 30.054C107.016 30.054 103.911 26.9498 103.911 23.1213C103.911 19.2927 107.016 16.1885 110.844 16.1885C114.673 16.1885 117.777 19.2927 117.777 23.1213Z"
      fill="#FF66FF"
    />
    <path
      d="M111.11 37.5254C111.11 41.3539 108.006 44.4582 104.178 44.4582C100.349 44.4582 97.2449 41.3539 97.2449 37.5254C97.2449 33.6969 100.349 30.5926 104.178 30.5926C108.006 30.5926 111.11 33.6969 111.11 37.5254Z"
      fill="#FF66FF"
    />
  </svg>
);
